<template>
  <div class="page">
    <v-alert
      v-for="alert in alerts"
      :key="alert.content"
      :type="alert.type"
    >
      {{ alert.message }}
    </v-alert>
    <Loading
      :loading="loading"
      message="Loading Page"
    />
    <div v-if="page">
      <h1 class="page-title">
        {{ page.name }}
      </h1>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="page-content"
        v-html="page.content"
      />
      <!--eslint-enable-->
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'

export default {
  components: {
    Loading,
  },
  data: function() {
    return {
      page: null,
      alerts: [],
      loading: false,
    }
  },
  created: function () {
    this.path = this.$route.path
    // Paths without trailing slashes are the paths of satan.
    if (!this.path.endsWith('/')) {
      this.path += '/'
    }
    this.getPage()
  },
  methods: {
    getPage() {
      this.loading = true
      return this.$store.dispatch('pages/getDetails', `/alliance/page/detail/?path=${this.path}`)
        .finally(() => {
          this.page = this.$store.getters['pages/getDetailsByAttribute']({key: 'path', value: this.path}) || null
          this.loading = false
          if (!this.$online && !this.page) {
            this.alerts.push({
              type: 'error',
              message: 'An internet connection is required to view this page.',
            })
          } else if (!this.page) {
            this.alerts.push({
              type: 'error',
              message: 'Failed to load page.',
            })
          }
        })
    },
  },
}
</script>
